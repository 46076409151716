// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("/opt/build/repo/src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-about-page-js": () => import("/opt/build/repo/src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-event-page-js": () => import("/opt/build/repo/src/templates/event-page.js" /* webpackChunkName: "component---src-templates-event-page-js" */),
  "component---src-templates-tags-js": () => import("/opt/build/repo/src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-examples-js": () => import("/opt/build/repo/src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("/opt/build/repo/src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-en-js": () => import("/opt/build/repo/src/pages/contact/index.en.js" /* webpackChunkName: "component---src-pages-contact-index-en-js" */),
  "component---src-pages-contact-index-ru-js": () => import("/opt/build/repo/src/pages/contact/index.ru.js" /* webpackChunkName: "component---src-pages-contact-index-ru-js" */),
  "component---src-pages-contact-index-ua-js": () => import("/opt/build/repo/src/pages/contact/index.ua.js" /* webpackChunkName: "component---src-pages-contact-index-ua-js" */),
  "component---src-pages-contact-thanks-js": () => import("/opt/build/repo/src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-events-index-en-js": () => import("/opt/build/repo/src/pages/events/index.en.js" /* webpackChunkName: "component---src-pages-events-index-en-js" */),
  "component---src-pages-events-index-ru-js": () => import("/opt/build/repo/src/pages/events/index.ru.js" /* webpackChunkName: "component---src-pages-events-index-ru-js" */),
  "component---src-pages-events-index-ua-js": () => import("/opt/build/repo/src/pages/events/index.ua.js" /* webpackChunkName: "component---src-pages-events-index-ua-js" */),
  "component---src-pages-tags-index-en-js": () => import("/opt/build/repo/src/pages/tags/index.en.js" /* webpackChunkName: "component---src-pages-tags-index-en-js" */),
  "component---src-pages-tags-index-ru-js": () => import("/opt/build/repo/src/pages/tags/index.ru.js" /* webpackChunkName: "component---src-pages-tags-index-ru-js" */),
  "component---src-pages-tags-index-ua-js": () => import("/opt/build/repo/src/pages/tags/index.ua.js" /* webpackChunkName: "component---src-pages-tags-index-ua-js" */)
}

